<template>
  <section class="title-widget">
    <div class="title-widget-content">
      <h3>{{ title }}</h3>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped>
.title-widget .title-widget-content {
  display: inline-block;
  border-bottom: 5px solid rgb(128 128 128);
}
</style>