<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <nav>
    <ul>
      <li><a href="/works" class="works-link">Works</a></li>
      <li class="crumbs-icon">
        <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon css-onkibi">
          <path fill="currentColor" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
        </svg>
      </li>
      <li class="work-page">{{ title }}</li>
    </ul>
  </nav>
</template>

<style scoped>
nav ul{
  display: flex;
  align-items: center;
}

nav ul li {
  list-style: none;
  color: white;
}

nav ul li *{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.works-link {
  font-size: 1.1rem;
  font-weight: 500;
  color: #ee3fb0;
}

.crumbs-icon {
  width: 20px;
}

.work-page {
  color: #0beaea;
  cursor: pointer;
  text-decoration: #0beaea solid underline;
}
</style>