<template>
  <NavbarComponents/>
  <router-view/>
  <footer class="py-5">
    <h5 class="py-1">&copy; 2024 John Lyon. All Right.</h5>
    <h5>Web Status: In maintenance</h5>
  </footer>
</template>

<style>
@import "@/assets/css/init.css";

* {
  font-family: "PingFang SC", serif;
  color: var(--theme-font);

}


nav a {
  font-weight: bold;
  color: #2c3e50;
}


footer {
  text-align: center;
}

footer * {
  color: var(--theme-footer-color) !important;
}
</style>
<script>
import NavbarComponents from "@/components/HeaderNavBar.vue";

export default {
  components: {
    NavbarComponents
  }
}
</script>