<script>
import TitleWidget from "@/widget/TitleWidget.vue";
import axios from "axios";

export default {
  components: {TitleWidget},
  data() {
    return {
      onTheWebHtml: ''
    }
  },
  created() {
    axios.get("/me/onTheWeb")
        .then(res => {
          let data = res.data.data;
          let onTheWebHtml = '';
          for (let i = 0; i < data.length; i++) {
            onTheWebHtml += `
            <button class="on-the-web-content py-2 px-3" data-url="${data[i].url}">
              <span class="symbols">${data[i].symbols}</span>
              <h5 class="name">${data[i].name}</h5>
            </button>`;
          }
          this.onTheWebHtml = onTheWebHtml;
        })
        .catch(err => {
          console.log(err);
        });
  },
  mounted() {
    // 事件委托
    this.$refs.content.addEventListener('click', this.handleButtonClick);
  },
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.content.removeEventListener('click', this.handleButtonClick);
  },
  methods: {
    handleButtonClick(event) {
      const button = event.target.closest('.on-the-web-content');
      if (button) {
        const url = button.getAttribute('data-url');
        if (url) {
          window.open(url, '_blank');  // 打开新页面
        }
      }
    }
  }
}
</script>

<template>
  <section>
    <TitleWidget title="On the Web"/>
    <div ref="content" v-html="onTheWebHtml"></div>
  </section>
</template>

<style>
.on-the-web-content {
  margin-bottom: .2rem;
  font-size: 1.2rem;
  background: none;
  border: none;
  border-radius: .4rem;
  transition: .3s ease;

}

.on-the-web-content, .on-the-web-content * {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-onWeb);
}

.on-the-web-content:nth-of-type(1) {
  margin-top: 1rem;
}

.on-the-web-content:hover {
  background: rgba(11, 234, 234, 0.36);
}

.on-the-web-content .name {
  padding-left: 1rem;
}
</style>