<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  methods: {
    openUrl() {
      if(this.url){
        window.open(this.url, "_self")
      }
    }
  }
}
</script>

<template>
  <button class="btn-info-center" @click="openUrl">{{ content }} <span ><svg viewBox="0 0 24 24" width="20" height="20"
                                                                        focusable="false"aria-hidden="true"><path fill="currentColor"
                                                                                                 d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span></button>
</template>

<style scoped>
.btn-info-center {
  border: none;
  border-radius: .3rem;
  padding: .5rem 1rem;
  background: var(--btn-info-center-background);
  font-weight: 800;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  transition: .2s ease;
}

.btn-info-center, .btn-info-center * {
  color: var(--btn-info-center-color);
}

.btn-info-center span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: .3rem;
}

.btn-info-center:hover {
  background: #55e5d0;
}
</style>