<script>
import {defineComponent} from "vue";
import UsesComponent from "@/components/UsesComponent.vue";

export default defineComponent({
  components: {UsesComponent}
})
</script>

<template>
  <div class="container">
    <UsesComponent />
  </div>
</template>

<style scoped>
.container {
  max-width: 624px;
  width: 100%;
  padding: 1rem 2rem;
}
</style>