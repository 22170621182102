<script>
import {defineComponent} from "vue";
import TitleWidget from "@/widget/TitleWidget.vue";
import axios from "axios";

export default defineComponent({
  components: {TitleWidget},
  created() {
    axios.get("/uses")
        .then(res => {
          let data = res.data.data
          let usesHtml = '';
          for (let i = 0; i < data.length; i++) {
            usesHtml += `
              <a class="uses-card my-3" href="${data[i].link}" data-id="${data[i].id}">
                <div class="uses-img">
                  <img src="${data[i].url}" alt="${data[i].name}">
                </div>
                <div class="uses-content py-3">
                  <h3>${data[i].name}</h3>
                  <small class="secondary my-1">来源: ${data[i].source}</small>
                  <h5 class="my-2">${data[i].info}</h5>
                </div>
              </a>
            `
          }

          this.$refs.uses.innerHTML = usesHtml
          this.addClickListener()
        })
        .catch(err => {
          console.log(err)
        })
  },
  methods: {
    addClickListener() {
      const usesCards = document.querySelectorAll('.uses-card')
      usesCards.forEach(card => {
        card.addEventListener('click', (event) => {
          const id = event.currentTarget.getAttribute('data-id')
          localStorage.setItem('workId', id)
        })
      })
    }
  }
})
</script>

<template>
  <section>
    <TitleWidget title="Uses"/>
    <div class="uses-container" ref="uses">

    </div>
  </section>
</template>

<style>
.uses-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.uses-container .uses-card {
  text-align: center;
  width: calc(50% - 10px);
}

.uses-container .uses-card .uses-img img {
  width: 100%;
  border-radius: 1rem;
}

.uses-container .uses-card .uses-content h3 {
  font-size: 1.2rem;
  font-weight: 300;
}

.uses-container .uses-card .uses-content h5 {
  font-size: .8rem;
  font-weight: 300;
}

a {
  text-decoration: none;
}

.secondary {
    color: var(--theme-secondary);
}

@media (max-width: 573px) {
  .uses-container .uses-card {
    width: calc(100%);
  }


}
</style>