<script>
import { defineComponent } from "vue";
import TitleWidget from "@/widget/TitleWidget.vue";
import axios from "axios";

export default defineComponent({
  components: { TitleWidget },
  created() {
    axios.get("/works")
      .then(res => {
        let data = res.data.data;
        let worksHtml = '';
        for (let i = 0; i < data.length; i++) {
          worksHtml += `
            <div class="works-card my-3" data-link="${data[i].link}" data-id="${data[i].id}">
              <div class="works-img">
                <img src="${data[i].url}" alt="${data[i].title}">
              </div>
              <div class="works-content py-3">
                <h3>${data[i].title}</h3>
                <h5 class="my-2">${data[i].info}</h5>
              </div>
            </div>
          `
        }

        this.$refs.works.innerHTML = worksHtml;
        this.addClickListener();
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    addClickListener() {
      const worksCards = document.querySelectorAll('.works-card');
      worksCards.forEach(card => {
        card.addEventListener('click', (event) => {
          const link = event.currentTarget.querySelector('h3').textContent;
          const id = event.currentTarget.getAttribute('data-id');

          // 路由跳转到指定路径并传递id查询参数
          this.$router.push({
            path: `/works/${link}`,
            query: { id: id }
          });
        });
      });
    }
  }
});
</script>

<template>
  <section>
    <TitleWidget title="Works"/>
    <div class="works-container" ref="works">
      <!-- 动态生成的作品卡片 -->
    </div>
  </section>
</template>

<style>
.works-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.works-container .works-card {
  text-align: center;
  width: calc(50% - 10px);
  cursor: pointer;
}

.works-container .works-card .works-img img {
  width: 100%;
  border-radius: 1rem;
}

.works-container .works-card .works-content h3 {
  font-size: 1.2rem;
  font-weight: 300;
}

.works-container .works-card .works-content h5 {
  font-size: .8rem;
  font-weight: 300;
}

a {
  text-decoration: none;
}

@media (max-width: 573px) {
  .works-container .works-card {
    width: calc(100%);
  }
}
</style>
