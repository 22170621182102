<script>

import {defineComponent} from "vue";
import TitleWidget from "@/widget/TitleWidget.vue";
import axios from "axios";
import InfoCenterWidget from "@/widget/button/InfoCenterWidget.vue";

export default defineComponent({
  components: {InfoCenterWidget, TitleWidget},
  created() {
    axios.get("/me/work")
        .then(res => {
          let data = res.data.data[0]
          this.$refs.work.innerHTML = data.content
        })
        .catch(err => {
          console.log(err)
        })
  }
})
</script>

<template>
  <section class="work">
    <TitleWidget title="Work"/>
    <div class="work-content py-4" ref="work"></div>
    <InfoCenterWidget content="My Info" url="/works"/>
  </section>
</template>

<style scoped>

.work .work-content {
  font-weight: 400;
}
</style>