import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import {createRouter, createWebHistory} from "vue-router";
import HomePage from "@/views/HomeView.vue";
import WorksPage from "@/views/WorksView.vue";
import UsesPage from "@/views/UsesView.vue";
import WorkInfoPage from "@/views/WorkPageView.vue";
import axios from "axios";

axios.defaults.baseURL = 'http://www.iamjohnlyon.com:8081/api';

const routes = [
    {
        path: '/', component: HomePage, meta: {
            title: 'John Lyon'
        },
    },
    {
        path: '/works', component: WorksPage, meta: {
            title: 'Works'
        }
    },
     {
        path: '/works/:link', component: WorkInfoPage, meta: {
            title: 'Work Page'
        }
    },
    {
        path: '/uses', component: UsesPage, meta: {
            title: 'Uses'
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.params.title ? to.params.title : to.meta.title;
    }
    next();
})
const app = createApp(App)
app.use(router)

app.mount('#app')
