<template>
  <nav>
    <div class="container">
      <div class="logo">
        <a href="/">X JohnLyon</a>
      </div>
      <div class="menu">
        <a href="/works">Works</a>
        <a href="/">Posts</a>
        <a href="/uses">Uses</a>
        <a href="https://github.com/JohnLyonX" class="github">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="20 0 512 512" height="1em"
               width="1em"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M256 32C132.3 32 32 134.9 32 261.7c0 101.5 64.2 187.5 153.2 217.9a17.56 17.56 0 003.8.4c8.3 0 11.5-6.1 11.5-11.4 0-5.5-.2-19.9-.3-39.1a102.4 102.4 0 01-22.6 2.7c-43.1 0-52.9-33.5-52.9-33.5-10.2-26.5-24.9-33.6-24.9-33.6-19.5-13.7-.1-14.1 1.4-14.1h.1c22.5 2 34.3 23.8 34.3 23.8 11.2 19.6 26.2 25.1 39.6 25.1a63 63 0 0025.6-6c2-14.8 7.8-24.9 14.2-30.7-49.7-5.8-102-25.5-102-113.5 0-25.1 8.7-45.6 23-61.6-2.3-5.8-10-29.2 2.2-60.8a18.64 18.64 0 015-.5c8.1 0 26.4 3.1 56.6 24.1a208.21 208.21 0 01112.2 0c30.2-21 48.5-24.1 56.6-24.1a18.64 18.64 0 015 .5c12.2 31.6 4.5 55 2.2 60.8 14.3 16.1 23 36.6 23 61.6 0 88.2-52.4 107.6-102.3 113.3 8 7.1 15.2 21.1 15.2 42.5 0 30.7-.3 55.5-.3 63 0 5.4 3.1 11.5 11.4 11.5a19.35 19.35 0 004-.4C415.9 449.2 480 363.1 480 261.7 480 134.9 379.7 32 256 32z"></path>
          </svg>
          <span class="px-2">Source</span></a>
      </div>
      <div class="toggle-container">
        <button class="toggle-theme" @click="toggleTheme">
          <svg v-if="isLightTheme" viewBox="0 0 24 24" focusable="false"
               aria-hidden="true">
            <path fill="white"
                  d="M21.4,13.7C20.6,13.9,19.8,14,19,14c-5,0-9-4-9-9c0-0.8,0.1-1.6,0.3-2.4c0.1-0.3,0-0.7-0.3-1 c-0.3-0.3-0.6-0.4-1-0.3C4.3,2.7,1,7.1,1,12c0,6.1,4.9,11,11,11c4.9,0,9.3-3.3,10.6-8.1c0.1-0.3,0-0.7-0.3-1 C22.1,13.7,21.7,13.6,21.4,13.7z"></path>
          </svg>
          <svg v-else viewBox="0 0 24 24" focusable="false"
               aria-hidden="true">
            <g stroke-linejoin="round" stroke-linecap="round" stroke-width="2" fill="none" stroke="black">
              <circle cx="12" cy="12" r="5"></circle>
              <path d="M12 1v2"></path>
              <path d="M12 21v2"></path>
              <path d="M4.22 4.22l1.42 1.42"></path>
              <path d="M18.36 18.36l1.42 1.42"></path>
              <path d="M1 12h2"></path>
              <path d="M21 12h2"></path>
              <path d="M4.22 19.78l1.42-1.42"></path>
              <path d="M18.36 5.64l1.42-1.42"></path>
            </g>
          </svg>

        </button>
        <button class="toggle-menu" @click="toggleMenu">
          <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon css-onkibi" aria-hidden="true">
            <path fill="currentColor"
                  d="M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z"></path>
          </svg>
        </button>
        <div class="toggle-menu-container" v-show="isMenuVisible">
          <ul>
            <a href="/works">
              <li>Works</li>
            </a>
            <a href="/">
              <li>Posts</li>
            </a>
            <a href="/uses">
              <li>Uses</li>
            </a>
            <a href="https://github.com/JohnLyonX">
              <li class="github">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                     width="1em"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M256 32C132.3 32 32 134.9 32 261.7c0 101.5 64.2 187.5 153.2 217.9a17.56 17.56 0 003.8.4c8.3 0 11.5-6.1 11.5-11.4 0-5.5-.2-19.9-.3-39.1a102.4 102.4 0 01-22.6 2.7c-43.1 0-52.9-33.5-52.9-33.5-10.2-26.5-24.9-33.6-24.9-33.6-19.5-13.7-.1-14.1 1.4-14.1h.1c22.5 2 34.3 23.8 34.3 23.8 11.2 19.6 26.2 25.1 39.6 25.1a63 63 0 0025.6-6c2-14.8 7.8-24.9 14.2-30.7-49.7-5.8-102-25.5-102-113.5 0-25.1 8.7-45.6 23-61.6-2.3-5.8-10-29.2 2.2-60.8a18.64 18.64 0 015-.5c8.1 0 26.4 3.1 56.6 24.1a208.21 208.21 0 01112.2 0c30.2-21 48.5-24.1 56.6-24.1a18.64 18.64 0 015 .5c12.2 31.6 4.5 55 2.2 60.8 14.3 16.1 23 36.6 23 61.6 0 88.2-52.4 107.6-102.3 113.3 8 7.1 15.2 21.1 15.2 42.5 0 30.7-.3 55.5-.3 63 0 5.4 3.1 11.5 11.4 11.5a19.35 19.35 0 004-.4C415.9 449.2 480 363.1 480 261.7 480 134.9 379.7 32 256 32z"></path>
                </svg>
                <span class="px-1">Source</span></li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isMenuVisible: false,
      isDarkTheme: localStorage.getItem('isDarkTheme') === 'true'
    };
  },
  computed: {
    isLightTheme() {
      return !this.isDarkTheme;
    },
    themeClass() {
      return this.isDarkTheme ? 'dark-theme' : 'light-theme';
    }
  },
  watch: {
    isDarkTheme(newVal) {
      localStorage.setItem('isDarkTheme', newVal);
      document.body.className = this.themeClass;
    }
  },
  mounted() {
    document.body.className = this.themeClass;
  },
  methods: {
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    toggleTheme() {
      this.isDarkTheme = !this.isDarkTheme;

    }
  }
};
</script>

<style scoped>
@import "@/assets/css/init.css";

nav {
  width: 100%;
  height: 56px;
  background-color: var(--theme-nav-background);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  z-index: 1000;
}

nav * {
  font-family: system-ui, sans-serif;
}

nav .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo {
  font-size: 1.2rem;
  font-weight: 500;
}

.logo a {
  color: var(--theme-nav-a);
  text-decoration: none;
  margin-right: var(--m-medin);
}

.menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: auto;
}

.menu a {
  color: var(--theme-nav-a);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 300;
  margin-right: var(--m-medin);
}

.github {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  width: 40px;
  height: 40px;
  border-radius: .5rem;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

button svg {
  width: 16px;
  height: 16px;
}

.toggle-theme {
  background: var(--theme-nav-toggle-background);
  margin-left: var(--m-medin);
}

.toggle-theme svg {
  fill: black !important;
}

.toggle-menu {
  border: 1px solid var(--theme-nav-toggle-border);
  color: white;
  margin-left: 0.5rem;
  display: none;
}

.toggle-menu-container {
  display: block;
  position: absolute;
  right: 25px;
  top: 65px;
  background: var(--theme-toggle-menu-container-background);
}

.toggle-menu-container ul li {
  list-style: none;
  padding: 1rem 3rem;
  width: 100%;
  text-align: center;
  transition: .2s ease;
}

.toggle-menu-container ul a, .toggle-menu-container ul a *, .toggle-menu-container ul a:hover {
  color: var(--theme-toggle-menu-container-color);
  text-decoration: none;
}

.toggle-menu-container ul li:hover {
  background: var(--theme-toggle-menu-container-color-hover);
}

@media (max-width: 768px) {
  nav .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .toggle-container {
    display: flex;
  }

  .toggle-menu {
    display: block;
  }

  .menu {
    display: none;
  }
}

@media (min-width: 769px) {
  .toggle-menu {
    display: none;
  }

  .menu {
    display: flex;
  }
}
</style>