<script>
import { defineComponent } from "vue";
import PublicModule from "@/widget/TitleWidget.vue";
import CrumbsWidget from "@/widget/crumbs/CrumbsWidget.vue";
import axios from "axios";

export default defineComponent({
  components: { CrumbsWidget, PublicModule },
  data() {
    return {
      work: null,
      watch: null,
    };
  },
  created() {
    const title = this.$route.params.link; // 从路由中获取title
    const id = this.$route.query.id; // 从查询参数中获取id
    const wid = this.$route.query.id; // 从查询参数中获取id

    axios
      .get(`/works/${title}`, {
        params: {
          id: id, // 将id作为查询参数
        },
        withCredentials: true,
      })
      .then((res) => {
        let data = res.data.data;
        document.title = data[0].title;
        this.work = data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
});
</script>

<template>
  <section class="container">
    <div class="work-info py-1">
      <svg t="1714295717063" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="4257" width="18" height="18">
        <path
            d="M957.971679 501.685074c-6.928806-9.940396-172.653245-243.818051-441.060321-243.818051-268.409123 0-434.131515 233.877655-441.061344 243.818051-10.730389 15.408945-10.730389 35.877115 0 51.284013 6.928806 9.94142 172.652222 243.819075 441.061344 243.819075 268.407076 0 434.130492-233.877655 441.060321-243.819075C968.701045 537.561166 968.701045 517.092995 957.971679 501.685074zM516.911358 751.877299c-248.028956 0-404.191622-224.550219-404.191622-224.550219s156.161642-224.551242 404.191622-224.551242c248.028956 0 404.190599 224.551242 404.190599 224.551242S764.940314 751.877299 516.911358 751.877299z"
            fill="#AAAAAA" p-id="4258"></path>
        <path
            d="M516.911358 347.687724c-99.206466 0-179.641403 80.434937-179.641403 179.64038s80.434937 179.64038 179.641403 179.64038c99.205443 0 179.639357-80.434937 179.639357-179.64038S616.116801 347.687724 516.911358 347.687724zM516.911358 662.057621c-74.2951 0-134.730541-60.435441-134.730541-134.729517s60.435441-134.730541 134.730541-134.730541c74.294077 0 134.728494 60.435441 134.728494 134.730541S591.205435 662.057621 516.911358 662.057621z"
            fill="#AAAAAA" p-id="4259"></path>
      </svg>
      <small class="px-1 reading">
        READING: <span>{{ work?.[0]?.watch }}</span></small>
    </div>
    <CrumbsWidget :title="work?.[0]?.title" class="py-1"/>
    <div class="work-img py-2">
      <img :src="work?.[0]?.url" :alt="work?.[0]?.title">
    </div>
    <div class="work-title py-2">
      <h1>{{ work?.[0]?.title }}</h1>
    </div>
    <div class="work-time py-2">
      <span>{{ work?.[0]?.date }}</span>
    </div>
    <div class="work-content py-2">
      <h3 v-html="work?.[0]?.content"></h3>
    </div>

  </section>
</template>

<style scoped>
.container {
  max-width: 752px;
  width: 100%;
  padding: 1rem 2rem;
}

.work-info {
  display: flex;
  height: 30px;
  align-items: center;
}

.work-info * {
  color: var(--theme-font);
  font-weight: 300;
}

.work-img {
  width: 460px;
}

.work-img img {
  width: 100%;
  border-radius: .5rem;
}

@media (max-width: 500px) {
  .work-img {
    width: 100%;
  }
}

.work-title {
  text-align: left;
  border-bottom: 5px solid var(--theme-title-use);
}

.work-title h1 {
  font-size: 2.3rem;
  font-weight: 500;
}

.work-time span {
  color: var(--theme-font);
  font-weight: 500;
}

.work-content * {
  font-weight: 300;
}
</style>
