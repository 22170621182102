<script>

import {defineComponent} from "vue";
import TitleWidget from "@/widget/TitleWidget.vue";
import axios from "axios";

export default defineComponent({
  components: {TitleWidget},
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
    },
  },
  created() {
    axios.get("/me/honor")
        .then(res => {
          let honorData = res.data.data;
          let honorHtml = '';
          for (let i = 0; i < honorData.length; i++) {
            honorHtml += `<li><span>${honorData[i].time}</span><strong>${honorData[i].honor}</strong> ${honorData[i].content}</li>`;
          }
          this.$refs.honor.innerHTML = honorHtml;
        })
        .catch(err => {
          console.log(err)
        })
  }
})
</script>

<template>
  <section class="honor">
    <TitleWidget title="Honor"/>
    <div class="honor-content py-3">
      <ul ref="honor">
      </ul>
<!--      <h3 class="expand my-1" @click="expand">expand more</h3>-->
    </div>
  </section>
</template>

<style>

.honor .honor-content ul li {
  list-style: none;
  line-height: 1.6rem;
}

.honor .honor-content ul li span {
  margin-right: 1rem;
}

.honor .honor-content ul li strong {
  color: #ff6a00;
}

.honor .honor-content .expand {
  cursor: pointer;
  text-decoration: underline;
  color: var(--theme-honor);
}
</style>