<script>
import {defineComponent} from "vue";
import PublicModule from "@/widget/TitleWidget.vue";
import InfoCenterWidget from "@/widget/button/InfoCenterWidget.vue";

export default defineComponent({
  components: {InfoCenterWidget, PublicModule}
})
</script>

<template>
  <section class="demo">
    <public-module title="My Demo"/>
    <div class="demo-container py-3">
      <div class="demo-card my-3">
        <div class="demo-img">
          <img src="https://defgec.oss-cn-guangzhou.aliyuncs.com/JohnLyon-Blog/demo/demo.jpg" alt="My Demo">
        </div>
        <div class="demo-content">
          <h4>BinShop E-commerce system</h4>
          <h6>A shopping mall system using java...</h6>
        </div>
      </div>

      <div class="demo-card my-3">
        <div class="demo-img">
          <img src="https://defgec.oss-cn-guangzhou.aliyuncs.com/JohnLyon-Blog/demo/demo.jpg" alt="My Demo">
        </div>
        <div class="demo-content">
          <h4>BinShop E-commerce system</h4>
          <h6>A shopping mall system using java...</h6>
        </div>
      </div>

      <div class="demo-card my-3">
        <div class="demo-img">
          <img src="https://defgec.oss-cn-guangzhou.aliyuncs.com/JohnLyon-Blog/demo/demo.jpg" alt="My Demo">
        </div>
        <div class="demo-content">
          <h4>BinShop E-commerce system</h4>
          <h6>A shopping mall system using java...</h6>
        </div>
      </div>

      <div class="demo-card my-3">
        <div class="demo-img">
          <img src="https://defgec.oss-cn-guangzhou.aliyuncs.com/JohnLyon-Blog/demo/demo.jpg" alt="My Demo">
        </div>
        <div class="demo-content">
          <h4>BinShop E-commerce system</h4>
          <h6>A shopping mall system using java...</h6>
        </div>
      </div>
    </div>
    <InfoCenterWidget content="Read more"/>
  </section>
</template>

<style scoped>


.demo-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.demo-container .demo-card {
  width: calc(50% - 15px);
}

.demo-container .demo-card img {
  width: 100%;
}
</style>