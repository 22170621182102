<script>

import {defineComponent} from "vue";
import PublicModule from "@/widget/TitleWidget.vue";

export default defineComponent({
  components: {PublicModule}
})
</script>

<template>
  <section class="posts">

    <div class="posts-container">
        <div class="posts-card my-3">
          <div class="posts-img">
            <img src="https://defgec.oss-cn-guangzhou.aliyuncs.com/JohnLyon-Blog/demo/demo.jpg" alt="My Demo">
          </div>
          <div class="posts-content">
            <h4>This is my Posts</h4>
            <h6>emmmmm no content...</h6>
          </div>
        </div>

        <div class="posts-card my-3">
          <div class="posts-img">
            <img src="https://defgec.oss-cn-guangzhou.aliyuncs.com/JohnLyon-Blog/demo/demo.jpg" alt="My Demo">
          </div>
          <div class="posts-content">
            <h4>This is my Posts</h4>
            <h6>emmmmm no content...</h6>
          </div>
        </div>
      </div>
  </section>
</template>

<style scoped>

.posts-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.posts-container .posts-card {
  width: calc(50% - 15px);
}

.posts-container .posts-card img {
  width: 100%;
}
</style>