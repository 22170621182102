<script>

import {defineComponent} from "vue";
import TitleWidget from "@/widget/TitleWidget.vue";
import axios from "axios";

export default defineComponent({
  components: {TitleWidget},
  created() {
    axios.get("/me/bio")
        .then(res => {
          let data = res.data.data
          let bioHtml = '';
          for (let i = 0; i < data.length; i++) {
                bioHtml += `<li><span>${data[i].time}</span>${data[i].content}</li>`
          }
          this.$refs.bio.innerHTML = bioHtml
        })
        .catch(err => {
          console.log(err)
        })
  }
})
</script>

<template>
  <section class="bio">
    <TitleWidget title="Bio"/>
    <div class="bio-content py-3">
      <ul ref="bio">

      </ul>
    </div>
  </section>
</template>

<style>

.bio .bio-content ul li {
  list-style: none;
}

.bio .bio-content ul li span {
  margin-right: 1rem;
}

</style>