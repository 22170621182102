<template>
  <div class="container">
    <section class="header-init">Hey! Welcome to My Blog</section>

    <!--  个人简介  -->
    <MeComponent/>
    <WorkComponent/>
    <BioComponent/>

    <!--  获取奖项  -->
    <HonorComponent/>

    <OnWebComponent />

    <!--  帖子  -->
    <PostsComponent/>
  </div>
</template>
<script>
import MeComponent from "@/components/MeComponent.vue";
import WorkComponent from "@/components/WorkComponent.vue";
import BioComponent from "@/components/BioComponent.vue";
import HonorComponent from "@/components/HonorComponent.vue";
import DemoComponent from "@/components/DemoComponent.vue";
import PostsComponent from "@/components/PostsComponent.vue";
import OnWebComponent from "@/components/OnTheWebComponents.vue";

export default {
  components: {PostsComponent, DemoComponent, HonorComponent, BioComponent, WorkComponent, MeComponent, OnWebComponent}
}
</script>
<style scoped>
.container {
  max-width: 624px;
  width: 100%;
  padding: 1rem 2rem;
}

section {
  margin: var(--m-small) 0;
}


.header-init {
  width: 100%;
  padding: 10px 10px;
  background: var(--theme-use);
  border-radius: .6rem;
  text-align: center;
}


</style>
