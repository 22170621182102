<template>
  <section class="me">
    <div class="about-me">
      <h1 class="name" ref="name"></h1>
      <h4>Profession <span class="profession" ref="profession"></span></h4>
      <h4><span class="office" ref="office"></span>: <b class="manager" ref="manager"></b></h4>
<!--      <div class="status" ref="status"></div>-->
    </div>
    <div class="aw">
      <img src="" ref="images" alt="John Lyon">
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  created() {
    axios.get('/me/findMySelf')
        .then(res => {
          let data = res.data.data[0]
          this.$refs.name.textContent = data.name;
          if (this.$refs.images.src) {
            this.$refs.images.src = data.url;
          } else {
            this.$refs.images.src = "https://defgec.oss-cn-guangzhou.aliyuncs.com/JohnLyon-Blog/avatar/johnlyon.png";
          }
          this.$refs.profession.textContent = data.profession;
          this.$refs.office.textContent = data.office;
          this.$refs.manager.textContent = data.manager;
          this.$refs.status.textContent = data.status;
        })
        .catch(error => {
          // 在这里处理错误
          console.error(error);
        });
  }
}
</script>

<style scoped>
.me {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.me .about-me h1 {
  font-size: 1.8rem;
  font-weight: 900;
}

.me .about-me h4 {
  font-weight: 300;
}

.manager {
  font-weight: 300;
}

.me .aw {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #FFFFFF;
}

.me .aw img {
  width: 100%;
}

@media (max-width: 500px) {
  .me {
    flex-direction: column;
  }

  .me .aw {
    margin: 1.8rem auto 0 auto;
  }
}
</style>