<script>
import {defineComponent} from "vue";
import WorksComponent from "@/components/WorksComponent.vue";

export default defineComponent({
  components: {WorksComponent}
})
</script>

<template>
  <div class="container">
    <WorksComponent />
  </div>
</template>

<style scoped>
.container {
  max-width: 624px;
  width: 100%;
  padding: 1rem 2rem;
}
</style>